// src/components/NewFeaturesSection.tsx
import React from "react";
import {
	CloudArrowUpIcon,
	LockClosedIcon,
	ServerIcon,
} from "@heroicons/react/20/solid";

const translations = {
	en: {
		sectionTitle: "Stream Professionally",
		title: "Single Platform for everything",
		description:
			"Discover a platform that enables you to stream, manage, and plan your athletics.",
		detail1:
			"Our goal is to deliver a comprehensive platform that simplifies athletics team management and offers streaming services. We understand the complexities of logistics, setups, and the need for multiple platforms to support competitions and team operations. Our aim is to eliminate the need for external tools, streamlining the management process for everyone involved.",
		detail2:
			"To ensure the best experience for users and organizations, we are pleased to accommodate special feature requests. Please note that additional development time may be required to implement these features.",
		noExperience: "No Streaming Experience? No Problem.",
		noExperienceDetail:
			"Most of our users who have experience with our predecessor, KISAC.Live, will already be familiar with how the system works. For those new to it, our simple guide allows setup in minutes. If you need further assistance, please contact our customer support team at heojoey2004@gmail.com.",
		features: [
			{
				title: "Game scores & Statistics",
				description:
					"Keep track of your gameplay with scoreboard widget that is displayed on top of your live video footage. Change scores as players score with real-time changes to scoreboard.",
			},
			{
				title: "Live Streaming via YouTube",
				description:
					"Stream your games live using our one-touch streaming service that automatically sets up video streaming for any game and or schedules you set up. (With your scoreboard as an overlay, of course.)",
			},
			{
				title: "Management Tools",
				description:
					"Effortlessly manage your athletics team with our built-in tools, making it easy to set up rosters, schedules, and your custom domain athletics page.",
			},
		],
	},
	ko: {
		sectionTitle: "전문적으로 스트리밍",
		title: "모든 것을 위한 단일 플랫폼",
		description:
			"운동을 스트리밍하고 관리하며 계획할 수 있는 플랫폼을 발견하십시오.",
		detail1:
			"우리의 목표는 운동 팀 관리를 단순화하고 스트리밍 서비스를 제공하는 포괄적인 플랫폼을 제공하는 것입니다. 우리는 로지스틱, 설정의 복잡성과 경쟁 및 팀 운영을 지원하기 위해 여러 플랫폼이 필요한 것을 이해합니다. 우리의 목표는 외부 도구의 필요성을 제거하여 관련된 모든 사람들을 위해 관리 프로세스를 간소화하는 것입니다.",
		detail2:
			"사용자와 조직의 최상의 경험을 보장하기 위해 특수 기능 요청을 기꺼이 수용할 것입니다. 이러한 기능을 구현하는 데 추가 개발 시간이 필요할 수 있습니다.",
		noExperience: "스트리밍 경험이 없으신가요? 문제 없습니다.",
		noExperienceDetail:
			"대부분의 사용자들은 우리의 전임자 KISAC.Live를 이미 경험했기 때문에 시스템 사용 방법에 익숙할 것입니다. 새로운 사용자에게는 간단한 가이드가 몇 분 안에 설정할 수 있도록 도와드립니다. 추가 도움이 필요하면 고객 지원 팀에 heojoey2004@gmail.com으로 연락해 주십시오.",
		features: [
			{
				title: "게임 점수 및 통계",
				description:
					"실시간으로 점수를 변경할 수 있는 라이브 비디오 영상 위에 표시되는 스코어보드 위젯으로 게임 플레이를 추적하십시오.",
			},
			{
				title: "YouTube를 통한 라이브 스트리밍",
				description:
					"우리의 원터치 스트리밍 서비스를 사용하여 설정한 모든 게임 및 일정에 대해 자동으로 비디오 스트리밍을 설정하여 게임을 실시간으로 스트리밍하십시오. (물론 스코어보드가 오버레이로 제공됩니다.)",
			},
			{
				title: "관리 도구",
				description:
					"내장 도구를 사용하여 손쉽게 운동 팀을 관리하고 명단, 일정 및 맞춤형 도메인 운동 페이지를 설정할 수 있습니다.",
			},
		],
	},
} as const;

type Language = keyof typeof translations;

const NewFeaturesSection: React.FC<{ language: Language }> = ({ language }) => {
	const {
		sectionTitle,
		title,
		description,
		detail1,
		detail2,
		noExperience,
		noExperienceDetail,
		features,
	} = translations[language];

	return (
		<div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
			<div className="absolute inset-0 -z-10 overflow-hidden">
				<svg
					className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
					aria-hidden="true"
				>
					<defs>
						<pattern
							id="e813992c-7d03-4cc4-a2bd-151760b470a0"
							width={200}
							height={200}
							x="50%"
							y={-1}
							patternUnits="userSpaceOnUse"
						>
							<path d="M100 200V.5M.5 .5H200" fill="none" />
						</pattern>
					</defs>
					<svg x="50%" y={-1} className="overflow-visible fill-gray-50">
						<path
							d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
							strokeWidth={0}
						/>
					</svg>
					<rect
						width="100%"
						height="100%"
						strokeWidth={0}
						fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"
					/>
				</svg>
			</div>
			<div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
				<div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
					<div className="lg:pr-4">
						<div className="lg:max-w-lg">
							<p className="text-base font-semibold leading-7 text-indigo-600">
								{sectionTitle}
							</p>
							<h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
								{title}
							</h1>
							<p className="mt-6 text-xl leading-8 text-gray-700">
								{description}
							</p>
						</div>
					</div>
				</div>
				<div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
					<img
						className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
						src="https://tailwindui.com/img/component-images/dark-project-app-screenshot.png"
						alt=""
					/>
				</div>
				<div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
					<div className="lg:pr-4">
						<div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
							<p>{detail1}</p>
							<ul role="list" className="mt-8 space-y-8 text-gray-600">
								{features.map((feature, index) => (
									<li key={index} className="flex gap-x-3">
										{index === 0 && (
											<CloudArrowUpIcon
												className="mt-1 h-5 w-5 flex-none text-indigo-600"
												aria-hidden="true"
											/>
										)}
										{index === 1 && (
											<LockClosedIcon
												className="mt-1 h-5 w-5 flex-none text-indigo-600"
												aria-hidden="true"
											/>
										)}
										{index === 2 && (
											<ServerIcon
												className="mt-1 h-5 w-5 flex-none text-indigo-600"
												aria-hidden="true"
											/>
										)}
										<span>
											<strong className="font-semibold text-gray-900">
												{feature.title}
											</strong>{" "}
											{feature.description}
										</span>
									</li>
								))}
							</ul>
							<p className="mt-8">{detail2}</p>
							<h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
								{noExperience}
							</h2>
							<p className="mt-6">{noExperienceDetail}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NewFeaturesSection;
