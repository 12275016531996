// src/components/dashboard/Navbar.tsx
import React, { useState, useEffect } from "react";
import {
	Disclosure,
	DisclosureButton,
	DisclosurePanel,
	Menu,
	MenuButton,
	MenuItem,
	MenuItems,
} from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { auth, db } from "../../services/firebase";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { getDoc, doc } from "firebase/firestore";
import Text_Logo_White from "../../assets/text_logo_white.png";
import ProfileImageModal from "./ProfileImageModal";
import NavbarSkeleton from "./NavbarSkeleton";
import streamliveImg from "../../assets/text_logo_white.png";
import disImg from "../../assets/dis.png";
import sjaImg from "../../assets/sjajeju.png";

const navigation = [
	{ name: "Dashboard", href: "/dashboard" },
	{ name: "Team", href: "/dashboard/teams" },
	{ name: "Schedules", href: "/dashboard/schedules" },
	{ name: "Rankings", href: "/dashboard/rankings" },
];
const userNavigation = [
	{ name: "Your Profile", href: "/dashboard/profile" },
	{ name: "Sign out", href: "#" },
];

function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(" ");
}

const Navbar: React.FC = () => {
	const [user, setUser] = useState<any>(null);
	const [organization, setOrganization] = useState<string | null>(null);
	const [role, setRole] = useState<string | null>(null);
	const [showProfileImageModal, setShowProfileImageModal] = useState(false);
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();
	const location = useLocation();

	const organizationImages: { [key: string]: string } = {
		streamlive: streamliveImg,
		DIS: disImg,
		SJA: sjaImg,
	};

	const getRoleColor = (role: string) => {
		switch (role) {
			case "Student":
				return "bg-gradient-to-r from-green-200 via-green-300 to-green-400 text-green-800";
			case "Faculty":
				return "bg-gradient-to-r from-blue-200 via-blue-300 to-blue-400 text-blue-800";
			case "Guest":
				return "bg-gradient-to-r from-yellow-200 via-yellow-300 to-yellow-400 text-yellow-800";
			case "Athletic Council":
				return "bg-gradient-to-r from-purple-200 via-purple-300 to-purple-400 text-purple-800";
			case "Athletic Director":
				return "bg-gradient-to-r from-red-200 via-red-300 to-red-400 text-red-800";
			case "Admin":
				return "bg-gradient-to-r from-yellow-300 via-yellow-400 to-yellow-600 text-yellow-900";
			default:
				return "bg-gradient-to-r from-gray-200 via-gray-300 to-gray-400 text-gray-800";
		}
	};
	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, async (user) => {
			if (user) {
				const userDoc = await getDoc(doc(db, "users", user.uid));
				if (userDoc.exists()) {
					const userData = userDoc.data();
					setOrganization(userData.organization);
					setRole(userData.role);
					if (!userData.photoURL) {
						setShowProfileImageModal(true);
					}
				}
				setUser(user);
			} else {
				navigate("/auth/login");
			}
			setLoading(false);
		});

		return () => unsubscribe();
	}, [navigate]);

	const handleSignOut = async () => {
		await signOut(auth);
		navigate("/auth/login");
	};

	if (loading) {
		return <NavbarSkeleton />;
	}

	return (
		<>
			<ProfileImageModal
				open={showProfileImageModal}
				setOpen={setShowProfileImageModal}
			/>
			<Disclosure as="nav" className="bg-gray-800">
				{({ open }) => (
					<>
						<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
							<div className="flex h-16 items-center justify-between">
								<div className="flex items-center">
									<div className="flex-shrink-0">
										<Link to="/dashboard" className="-m-1.5 p-1.5">
											<img
												className="h-12 w-auto"
												src={Text_Logo_White}
												alt="streamlive"
											/>
										</Link>
									</div>
									<div className="hidden md:block">
										<div className="ml-10 flex items-baseline space-x-4">
											{navigation.map((item) => (
												<Link
													key={item.name}
													to={item.href}
													className={classNames(
														location.pathname === item.href
															? "bg-gray-900 text-white"
															: "text-gray-300 hover:bg-gray-700 hover:text-white",
														"rounded-md px-3 py-2 text-sm font-medium"
													)}
													aria-current={
														location.pathname === item.href ? "page" : undefined
													}
												>
													{item.name}
												</Link>
											))}
										</div>
									</div>
								</div>
								<div className="hidden md:flex items-center">
									{organization && role && (
										<span
											className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium text-white ring-1 ring-inset ring-gray-500/10 ${getRoleColor(
												role
											)}`}
										>
											<img
												src={organizationImages[organization]}
												alt={organization}
												className="h-5 w-auto mr-2 rounded-md"
											/>
											{role}
										</span>
									)}
									<div className="ml-4 flex items-center md:ml-6">
										{/* Profile dropdown */}
										<Menu as="div" className="relative ml-3">
											<div>
												<MenuButton className="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
													<span className="absolute -inset-1.5" />
													<span className="sr-only">Open user menu</span>
													<img
														className="h-8 w-8 rounded-full"
														src={
															user?.photoURL ||
															"https://via.placeholder.com/150"
														}
														alt=""
													/>
												</MenuButton>
											</div>
											<MenuItems
												transition
												className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
											>
												{userNavigation.map((item) => (
													<MenuItem key={item.name}>
														{({ active }) => (
															<Link
																to={item.href}
																className={classNames(
																	active ? "bg-gray-100" : "",
																	"block px-4 py-2 text-sm text-gray-700"
																)}
																onClick={
																	item.name === "Sign out"
																		? handleSignOut
																		: undefined
																}
															>
																{item.name}
															</Link>
														)}
													</MenuItem>
												))}
											</MenuItems>
										</Menu>
									</div>
								</div>
								<div className="-mr-2 flex md:hidden">
									{/* Mobile menu button */}
									<DisclosureButton className="relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
										<span className="absolute -inset-0.5" />
										<span className="sr-only">Open main menu</span>
										{open ? (
											<XMarkIcon className="block h-6 w-6" aria-hidden="true" />
										) : (
											<Bars3Icon className="block h-6 w-6" aria-hidden="true" />
										)}
									</DisclosureButton>
								</div>
							</div>
						</div>

						<DisclosurePanel className="md:hidden">
							<div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
								{navigation.map((item) => (
									<DisclosureButton
										key={item.name}
										as={Link}
										to={item.href}
										className={classNames(
											location.pathname === item.href
												? "bg-gray-900 text-white"
												: "text-gray-300 hover:bg-gray-700 hover:text-white",
											"block rounded-md px-3 py-2 text-base font-medium"
										)}
										aria-current={
											location.pathname === item.href ? "page" : undefined
										}
									>
										{item.name}
									</DisclosureButton>
								))}
							</div>
							<div className="border-t border-gray-700 pb-3 pt-4">
								<div className="flex items-center px-5">
									<div className="flex-shrink-0">
										<img
											className="h-10 w-10 rounded-full"
											src={user?.photoURL || "https://via.placeholder.com/150"}
											alt=""
										/>
									</div>
									<div className="ml-3">
										<div className="text-base font-medium leading-none text-white">
											{user?.displayName || "User"}
										</div>
										<div className="text-sm font-medium leading-none text-gray-400">
											{user?.email}
										</div>
									</div>
								</div>
								<div className="mt-3 space-y-1 px-2">
									{userNavigation.map((item) => (
										<DisclosureButton
											key={item.name}
											as={Link}
											to={item.href}
											className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
											onClick={
												item.name === "Sign out" ? handleSignOut : undefined
											}
										>
											{item.name}
										</DisclosureButton>
									))}
								</div>
							</div>
						</DisclosurePanel>
					</>
				)}
			</Disclosure>
		</>
	);
};

export default Navbar;
