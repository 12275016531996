// src/pages/dashboard/ProfilePage.tsx
import React, { useState, useEffect } from "react";
import { getDoc, doc, updateDoc } from "firebase/firestore";
import { auth, db, storage } from "../../services/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { updateProfile } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import streamliveImg from "../../assets/text_logo_white.png";
import disImg from "../../assets/dis.png";
import sjaImg from "../../assets/sjajeju.png";

const ProfilePage: React.FC = () => {
	const [displayName, setDisplayName] = useState("");
	const [email, setEmail] = useState("");
	const [photoURL, setPhotoURL] = useState("");
	const [organization, setOrganization] = useState("");
	const [role, setRole] = useState("");
	const [newPhoto, setNewPhoto] = useState<File | null>(null);
	const [error, setError] = useState<string | null>(null);
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();
	const organizationImages: { [key: string]: string } = {
		streamlive: streamliveImg,
		DIS: disImg,
		SJA: sjaImg,
	};

	useEffect(() => {
		const fetchUserData = async () => {
			const user = auth.currentUser;
			if (user) {
				const userDoc = await getDoc(doc(db, "users", user.uid));
				if (userDoc.exists()) {
					const userData = userDoc.data();
					setDisplayName(userData.displayName);
					setEmail(userData.email);
					setPhotoURL(userData.photoURL);
					setOrganization(userData.organization);
					setRole(userData.role);
				}
			}
			setLoading(false);
		};

		fetchUserData();
	}, []);

	const handleUpdateProfile = async (e: React.FormEvent) => {
		e.preventDefault();
		setError(null);
		try {
			const user = auth.currentUser;
			if (user) {
				if (newPhoto) {
					const storageRef = ref(storage, `profilePictures/${user.uid}`);
					await uploadBytes(storageRef, newPhoto);
					const downloadURL = await getDownloadURL(storageRef);
					setPhotoURL(downloadURL);
					await updateProfile(user, { photoURL: downloadURL });
					await updateDoc(doc(db, "users", user.uid), {
						photoURL: downloadURL,
					});
				}
				await updateProfile(user, { displayName });
				await updateDoc(doc(db, "users", user.uid), { displayName });

				navigate("/dashboard");
			}
		} catch (error) {
			setError("Failed to update profile. Please try again.");
		}
	};

	const handlePhotoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files && e.target.files[0]) {
			setNewPhoto(e.target.files[0]);
		}
	};

	const getRoleColor = (role: string) => {
		switch (role) {
			case "Student":
				return "bg-gradient-to-r from-green-200 via-green-300 to-green-400 text-green-800";
			case "Faculty":
				return "bg-gradient-to-r from-blue-200 via-blue-300 to-blue-400 text-blue-800";
			case "Guest":
				return "bg-gradient-to-r from-yellow-200 via-yellow-300 to-yellow-400 text-yellow-800";
			case "Athletic Council":
				return "bg-gradient-to-r from-purple-200 via-purple-300 to-purple-400 text-purple-800";
			case "Athletic Director":
				return "bg-gradient-to-r from-red-200 via-red-300 to-red-400 text-red-800";
			case "Admin":
				return "bg-gradient-to-r from-yellow-300 via-yellow-400 to-yellow-600 text-yellow-900";
			default:
				return "bg-gradient-to-r from-gray-200 via-gray-300 to-gray-400 text-gray-800";
		}
	};

	if (loading) {
		return (
			<div>
				<div
					className="flex items-center justify-center min-h-screen"
					role="status"
				>
					<svg
						aria-hidden="true"
						className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
						viewBox="0 0 100 101"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
							fill="currentColor"
						/>
						<path
							d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
							fill="currentFill"
						/>
					</svg>
					<span className="sr-only">Loading...</span>
				</div>
			</div>
		);
	}

	return (
		<div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
			<div className="sm:mx-auto sm:w-full sm:max-w-sm">
				<h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
					Your Profile
				</h2>
			</div>
			<div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
				<form className="space-y-6" onSubmit={handleUpdateProfile}>
					<div>
						<label
							htmlFor="displayName"
							className="block text-sm font-medium leading-6 text-gray-900"
						>
							Display Name
						</label>
						<div className="mt-2">
							<input
								id="displayName"
								name="displayName"
								type="text"
								autoComplete="name"
								required
								value={displayName}
								onChange={(e) => setDisplayName(e.target.value)}
								className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
							/>
						</div>
					</div>
					<div>
						<label
							htmlFor="email"
							className="block text-sm font-medium leading-6 text-gray-900"
						>
							Email address
						</label>
						<div className="mt-2">
							<input
								id="email"
								name="email"
								type="email"
								autoComplete="email"
								disabled
								value={email}
								className="block w-full rounded-md border-0 bg-gray-200 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
							/>
						</div>
					</div>
					<div>
						<label
							htmlFor="organization"
							className="block text-sm font-medium leading-6 text-gray-900"
						>
							Organization
						</label>
						<div className="mt-2">
							<input
								id="organization"
								name="organization"
								type="text"
								disabled
								value={organization}
								className="block w-full rounded-md border-0 bg-gray-200 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
							/>
						</div>
					</div>
					<div>
						<label
							htmlFor="role"
							className="block text-sm font-medium leading-6 text-gray-900"
						>
							Role
						</label>
						<div className="mt-2">
							<input
								id="role"
								name="role"
								type="text"
								disabled
								value={role}
								className="block w-full rounded-md border-0 bg-gray-200 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
							/>
						</div>
					</div>
					<div className="flex space-x-2 mt-4">
						{organization && role && (
							<span
								className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium text-white ring-1 ring-inset ring-gray-500/10 ${getRoleColor(
									role
								)}`}
							>
								<img
									src={organizationImages[organization]}
									alt={organization}
									className="h-5 w-auto mr-2 rounded-md"
								/>
								{role}
							</span>
						)}
					</div>
					<div>
						<label
							htmlFor="photo"
							className="block text-sm font-medium leading-6 text-gray-900"
						>
							Profile Picture
						</label>
						<div className="mt-2">
							<input
								id="photo"
								name="photo"
								type="file"
								accept="image/*"
								onChange={handlePhotoChange}
								className="block w-full text-sm text-gray-900"
							/>
							{photoURL && (
								<img
									src={photoURL}
									alt="Profile"
									className="mt-2 h-20 w-20 rounded-full"
								/>
							)}
						</div>
					</div>
					{error && <p className="mt-2 text-sm text-red-600">{error}</p>}
					<div>
						<button
							type="submit"
							className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
						>
							Update Profile
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default ProfilePage;
