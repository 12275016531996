// src/pages/company/CompanyHomePage.tsx
import { useState } from "react";
import Text_Logo from "../../assets/text_logo.png";
import { Dialog, DialogPanel } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import CompanyFeature from "../../components/company/Features";
import Team from "../../components/company/Team";
import Usedby from "../../components/company/Usedby";
import Pricing from "../../components/company/Pricing";
import Footer from "../../components/Footer";

const navigation = [
	// { name: "Product", href: "/product" },
	// { name: "Features", href: "/features" },
	{ name: "Advertisement", href: "/advertisement" },
];

const translations = {
	en: {
		title: "Redefining the Future of Sports Streaming",
		description:
			'Experience the next evolution in sports entertainment with our platform, "Redefining the Future of Sports Streaming." Enjoy high-definition live sports, instant replays, and interactive stats, all in one place. Access your favorite events anytime, anywhere, and never miss a moment of the action.',
		getStarted: "Get started",
		learnMore: "Learn more",
	},
	ko: {
		title: "스포츠 스트리밍의 미래 재정의",
		description:
			'"스포츠 스트리밍의 미래 재정의" 플랫폼을 통해 스포츠 엔터테인먼트의 다음 진화를 경험하세요. 고화질 라이브 스포츠, 즉각 재생, 상호작용 통계를 한 곳에서 모두 즐기세요. 언제 어디서나 좋아하는 이벤트에 접속하고 액션의 순간을 놓치지 마세요.',
		getStarted: "시작하기",
		learnMore: "자세히 알아보기",
	},
} as const;
const posts = [
	{
		id: 1,
		title: "KISAC.Live Acquired by Thestreamlive Company",
		content: `
        <p>KISAC.Live Acquired by Thestreamlive Company: Transforming into a SaaS Platform</p>
  
        <p>We are excited to announce that KISAC.Live, originally developed by Prebeyond, Inc., has been acquired by Thestreamlive Company. Operations of KISAC.Live will continue uninterrupted through the 2024-2025 fiscal year. Following this period, the platform will be integrated into Thestreamlive's offerings and serviced accordingly. All existing streaming services will transition to Thestreamlive's platform, with appropriate licensing available for interested parties.</p>
  
        <h3>About KISAC.Live</h3>
  
        <p>KISAC.Live has established itself as a centralized information provider and a one-stop sports destination. The platform is designed to offer a comprehensive solution for managing athletic teams, whether they belong to schools, amateur leagues, or professional organizations. It aims to eliminate the need for external tools or platforms by simplifying the management process for all involved parties.</p>
  
        <h3>Achievements and Impact</h3>
  
        <p>KISAC.Live has made significant contributions to the sports community:</p>
        <ul>
          <li>60,000+ views during the 2022-2023 season on YouTube, showcasing its reach and engagement.</li>
          <li>500+ beta users tested the software, providing valuable feedback and helping refine the platform.</li>
          <li>14+ schools are unofficially registered in the database, indicating its widespread adoption.</li>
          <li>400+ individual athletes' data including scores and number of games played, highlighting its comprehensive tracking capabilities.</li>
          <li>40,000+ website visits demonstrating its popularity and user engagement.</li>
          <li>6+ sponsors during the 2022-2023 season, underscoring its credibility and support from the community.</li>
        </ul>
  
        <h3>Key Features</h3>
  
        <p>KISAC.Live offers a range of features designed to enhance the management and streaming of sports events:</p>
        <ul>
          <li><strong>Live Scores and Statistics:</strong> Real-time updates and detailed statistics to keep track of game progress.</li>
          <li><strong>Team Ranking:</strong> Comprehensive team ranking system to assess performance.</li>
          <li><strong>Game Score:</strong> Accurate and up-to-date game scoring.</li>
          <li><strong>Calendar/Game Schedule:</strong> Easy scheduling and calendar management for events.</li>
          <li><strong>Live Stream:</strong> High-quality live streaming capabilities.</li>
          <li><strong>School Team Page and Conference Page:</strong> Dedicated pages for schools and conferences to manage their teams and events.</li>
          <li><strong>Team Management (Rosters):</strong> Efficient management of team rosters.</li>
          <li><strong>Generate Schedule (Tournaments):</strong> Automated scheduling for tournaments.</li>
        </ul>
  
        <h3>Transition to a SaaS Platform</h3>
  
        <p>Thestreamlive Company is committed to transforming KISAC.Live from a local software solution into a Software as a Service (SaaS) platform. This transition aims to make the innovative features of KISAC.Live accessible to a broader audience, enabling more users to benefit from its comprehensive capabilities. The new SaaS platform will provide seamless access to all the features, along with enhanced scalability, reliability, and support.</p>
  
        <h3>Future Plans</h3>
  
        <p>Our goal is to integrate KISAC.Live into Thestreamlive's suite of offerings, ensuring that users continue to receive top-notch streaming and management solutions for athletic events. We plan to expand the platform's reach, making it available to more schools, leagues, and organizations worldwide. By leveraging the SaaS model, we will offer flexible subscription plans, making it easier for users to adopt and utilize the platform according to their needs.</p>
  
        <p><strong>Join Us on This Journey</strong></p>
  
        <p>We invite all existing and prospective users to join us on this exciting journey as we enhance and expand KISAC.Live. Together, we can revolutionize the way athletic events are managed and streamed, providing an unparalleled experience for teams, athletes, and fans alike.</p>
  
        <p>If you are interested in learning more about the transition or have any questions, please feel free to reach out to us at contact@prebeyond.com. We are here to assist you and ensure a smooth integration of our software into your existing systems.</p>
  
        <p>The acquisition of KISAC.Live by Thestreamlive Company marks a significant milestone in our journey. We are excited about the future and the possibilities that this partnership will bring. Our commitment to providing top-notch streaming and management solutions for athletic events remains stronger than ever.</p>
      `,
		date: "Mar 16, 2024",
		datetime: "2024-03-16",
		category: { title: "Company News", href: "#" },
		author: {
			name: "Joey Heo",
			role: "Founder / CEO / CTO",
			href: "#",
			imageUrl:
				"https://media.licdn.com/dms/image/D5603AQEnw3a6BS1Oag/profile-displayphoto-shrink_400_400/0/1699340196506?e=1721865600&v=beta&t=eydC7sL56OO6KVJFE9IX4rt1R-BYeYaEoacK0YSWZeA",
		},
	},
	// More posts...
];

type Language = keyof typeof translations;

const CompanyHomePage: React.FC = () => {
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
	const [language, setLanguage] = useState<Language>("en");

	const handleLanguageToggle = () => {
		setLanguage((prevLanguage) => (prevLanguage === "en" ? "ko" : "en"));
	};

	const { title, description, getStarted, learnMore } = translations[language];
	const post = posts[0]; // Always display the first post
	return (
		<div className="bg-white">
			<header className="absolute inset-x-0 top-0 z-50">
				<nav
					className="flex items-center justify-between p-6 lg:px-8"
					aria-label="Global"
				>
					<div className="flex lg:flex-1">
						<a href="/" className="-m-1.5 p-1.5">
							<img className="h-12 w-auto" src={Text_Logo} alt="streamlive" />
						</a>
					</div>
					<div className="flex lg:hidden">
						<button
							type="button"
							className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
							onClick={() => setMobileMenuOpen(true)}
						>
							<span className="sr-only">Open main menu</span>
							<Bars3Icon className="h-6 w-6" aria-hidden="true" />
						</button>
					</div>
					<div className="hidden lg:flex lg:gap-x-12">
						{navigation.map((item) => (
							<a
								key={item.name}
								href={item.href}
								className="text-sm font-semibold leading-6 text-gray-900"
							>
								{item.name}
							</a>
						))}
					</div>
					<div className="hidden lg:flex lg:flex-1 lg:justify-end">
						<a
							href="/auth/login"
							className="text-sm font-semibold leading-6 text-gray-900"
						>
							Log in <span aria-hidden="true">&rarr;</span>
						</a>
					</div>
				</nav>
				<Dialog
					className="lg:hidden"
					open={mobileMenuOpen}
					onClose={() => setMobileMenuOpen(false)}
				>
					<div className="fixed inset-0 z-50" />
					<DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
						<div className="flex items-center justify-between">
							<a href="/" className="-m-1.5 p-1.5">
								<img className="h-8 w-auto" src={Text_Logo} alt="streamlive" />
							</a>
							<button
								type="button"
								className="-m-2.5 rounded-md p-2.5 text-gray-700"
								onClick={() => setMobileMenuOpen(false)}
							>
								<span className="sr-only">Close menu</span>
								<XMarkIcon className="h-6 w-6" aria-hidden="true" />
							</button>
						</div>
						<div className="mt-6 flow-root">
							<div className="-my-6 divide-y divide-gray-500/10">
								<div className="space-y-2 py-6">
									{navigation.map((item) => (
										<a
											key={item.name}
											href={item.href}
											className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
										>
											{item.name}
										</a>
									))}
								</div>
								<div className="py-6">
									<a
										href="/auth/login"
										className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
									>
										Log in
									</a>
								</div>
							</div>
						</div>
					</DialogPanel>
				</Dialog>
			</header>
			<div className="relative isolate px-6 pt-14 lg:px-8">
				<div
					className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
					aria-hidden="true"
				>
					<div
						className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
						style={{
							clipPath:
								"polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
						}}
					/>
				</div>
			</div>
			<div className="bg-white py-24 sm:py-32">
				<div className="mx-auto max-w-7xl px-6 lg:px-8">
					<article className="max-w-3xl mx-auto">
						<header className="mb-8">
							<div className="flex items-center gap-x-4 text-xs">
								<time dateTime={post.datetime} className="text-gray-500">
									{post.date}
								</time>
								<a
									href={post.category.href}
									className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
								>
									{post.category.title}
								</a>
							</div>
							<h1 className="mt-4 text-4xl font-bold tracking-tight text-gray-900">
								{post.title}
							</h1>
							<div className="relative mt-8 flex items-center gap-x-4">
								<img
									src={post.author.imageUrl}
									alt={post.author.name}
									className="h-10 w-10 rounded-full bg-gray-50"
								/>
								<div className="text-sm leading-6">
									<p className="font-semibold text-gray-900">
										<a href={post.author.href}>
											<span className="absolute inset-0" />
											{post.author.name}
										</a>
									</p>
									<p className="text-gray-600">{post.author.role}</p>
								</div>
							</div>
						</header>
						<section
							className="text-lg leading-8 text-gray-700"
							dangerouslySetInnerHTML={{ __html: post.content }}
						/>
					</article>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default CompanyHomePage;
