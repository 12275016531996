// src/components/LeadershipSection.tsx
import React from "react";

const translations = {
	en: {
		sectionTitle: "Meet our team",
		description:
			"With over three years of industry experience and currently pursuing a Bachelor of Science in Computer Engineering with a concentration in Computer Systems at the University of California, Santa Cruz, Joey Heo is a distinguished expert in developing cutting-edge software platforms. His extensive knowledge and proven expertise position him as a leader in the field, driving innovation and excellence in every project he undertakes.",
		people: [
			{
				name: "Joey Heo",
				role: "Founder / CEO / CTO",
				imageUrl:
					"https://media.licdn.com/dms/image/D5603AQEnw3a6BS1Oag/profile-displayphoto-shrink_400_400/0/1699340196506?e=1721865600&v=beta&t=eydC7sL56OO6KVJFE9IX4rt1R-BYeYaEoacK0YSWZeA",
			},
		],
	},
	ko: {
		sectionTitle: "우리 팀을 소개합니다",
		description:
			"산타 크루즈 캘리포니아 대학교에서 컴퓨터 시스템 전공으로 컴퓨터 공학 학사 과정을 밟고 있는 Joey Heo는 3년 이상의 업계 경험을 가진 전문가입니다. 그의 광범위한 지식과 입증된 전문성은 그를 분야의 리더로 자리매김하게 하며, 그는 맡은 모든 프로젝트에서 혁신과 우수성을 이끌어내고 있습니다.",
		people: [
			{
				name: "Joey Heo",
				role: "창립자 / CEO / CTO",
				imageUrl:
					"https://media.licdn.com/dms/image/D5603AQEnw3a6BS1Oag/profile-displayphoto-shrink_400_400/0/1699340196506?e=1721865600&v=beta&t=eydC7sL56OO6KVJFE9IX4rt1R-BYeYaEoacK0YSWZeA",
			},
		],
	},
} as const;

type Language = keyof typeof translations;

const LeadershipSection: React.FC<{ language: Language }> = ({ language }) => {
	const { sectionTitle, description, people } = translations[language];

	return (
		<div className="bg-white py-24 sm:py-32">
			<div className="mx-auto max-w-3xl px-6 text-center">
				<h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
					{sectionTitle}
				</h2>
				<p className="mt-6 text-lg leading-8 text-gray-600">{description}</p>
				<ul role="list" className="mt-10 flex justify-center gap-x-8 gap-y-12">
					{people.map((person) => (
						<li key={person.name} className="flex flex-col items-center">
							<img
								className="h-32 w-32 rounded-full"
								src={person.imageUrl}
								alt={person.name}
							/>
							<div className="mt-4 text-center">
								<h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
									{person.name}
								</h3>
								<p className="text-sm font-semibold leading-6 text-indigo-600">
									{person.role}
								</p>
							</div>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};

export default LeadershipSection;
