// src/components/dashboard/NavbarSkeleton.tsx
import React from "react";
import {
	Disclosure,
	DisclosureButton,
	DisclosurePanel,
	Menu,
	MenuButton,
	MenuItem,
	MenuItems,
} from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link, useLocation } from "react-router-dom";
import Text_Logo_White from "../../assets/text_logo_white.png";

const navigation = [
	{ name: "Dashboard", href: "/dashboard" },
	{ name: "Team", href: "/dashboard/teams" },
	{ name: "Schedules", href: "/dashboard/schedules" },
	{ name: "Rankings", href: "/dashboard/rankings" },
];
const userNavigation = [
	{ name: "Your Profile", href: "/dashboard/profile" },
	{ name: "Sign out", href: "#" },
];

function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(" ");
}

const NavbarSkeleton: React.FC = () => {
	const location = useLocation();

	return (
		<Disclosure as="nav" className="bg-gray-800">
			{({ open }) => (
				<>
					<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
						<div className="flex h-16 items-center justify-between">
							<div className="flex items-center">
								<div className="flex-shrink-0">
									<Link to="/dashboard" className="-m-1.5 p-1.5">
										<div className="h-12 w-auto bg-gray-700 animate-pulse" />
									</Link>
								</div>
								<div className="hidden md:block">
									<div className="ml-10 flex items-baseline space-x-4">
										{navigation.map((item) => (
											<div
												key={item.name}
												className={classNames(
													location.pathname === item.href
														? "bg-gray-900 text-white"
														: "bg-gray-700",
													"rounded-md px-3 py-2 text-sm font-medium animate-pulse"
												)}
												aria-current={
													location.pathname === item.href ? "page" : undefined
												}
											>
												<div className="h-4 w-24 bg-gray-600 animate-pulse"></div>
											</div>
										))}
									</div>
								</div>
							</div>
							<div className="hidden md:block">
								<div className="ml-4 flex items-center md:ml-6">
									<div className="relative rounded-full bg-gray-700 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800 animate-pulse">
										<span className="absolute -inset-1.5" />
										<span className="sr-only">View notifications</span>
										<BellIcon className="h-6 w-6" aria-hidden="true" />
									</div>

									{/* Profile dropdown */}
									<Menu as="div" className="relative ml-3">
										<div>
											<MenuButton className="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800 animate-pulse">
												<span className="absolute -inset-1.5" />
												<span className="sr-only">Open user menu</span>
												<div className="h-8 w-8 rounded-full bg-gray-700 animate-pulse"></div>
											</MenuButton>
										</div>
										<MenuItems
											transition
											className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
										>
											{userNavigation.map((item) => (
												<MenuItem key={item.name}>
													{({ active }) => (
														<Link
															to={item.href}
															className={classNames(
																active ? "bg-gray-100" : "",
																"block px-4 py-2 text-sm text-gray-700"
															)}
														>
															<div className="h-4 w-24 bg-gray-600 animate-pulse"></div>
														</Link>
													)}
												</MenuItem>
											))}
										</MenuItems>
									</Menu>
								</div>
							</div>
							<div className="-mr-2 flex md:hidden">
								{/* Mobile menu button */}
								<DisclosureButton className="relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
									<span className="absolute -inset-0.5" />
									<span className="sr-only">Open main menu</span>
									{open ? (
										<XMarkIcon className="block h-6 w-6" aria-hidden="true" />
									) : (
										<Bars3Icon className="block h-6 w-6" aria-hidden="true" />
									)}
								</DisclosureButton>
							</div>
						</div>
					</div>

					<DisclosurePanel className="md:hidden">
						<div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
							{navigation.map((item) => (
								<DisclosureButton
									key={item.name}
									as={Link}
									to={item.href}
									className={classNames(
										location.pathname === item.href
											? "bg-gray-900 text-white"
											: "bg-gray-700",
										"block rounded-md px-3 py-2 text-base font-medium animate-pulse"
									)}
									aria-current={
										location.pathname === item.href ? "page" : undefined
									}
								>
									<div className="h-4 w-24 bg-gray-600 animate-pulse"></div>
								</DisclosureButton>
							))}
						</div>
						<div className="border-t border-gray-700 pb-3 pt-4">
							<div className="flex items-center px-5">
								<div className="flex-shrink-0">
									<div className="h-10 w-10 rounded-full bg-gray-700 animate-pulse"></div>
								</div>
								<div className="ml-3">
									<div className="h-4 w-24 bg-gray-600 animate-pulse"></div>
									<div className="h-4 w-24 bg-gray-600 animate-pulse mt-2"></div>
								</div>
								<div className="relative ml-auto flex-shrink-0 rounded-full bg-gray-700 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800 animate-pulse">
									<span className="absolute -inset-1.5" />
									<span className="sr-only">View notifications</span>
									<BellIcon className="h-6 w-6" aria-hidden="true" />
								</div>
							</div>
							<div className="mt-3 space-y-1 px-2">
								{userNavigation.map((item) => (
									<DisclosureButton
										key={item.name}
										as={Link}
										to={item.href}
										className="block rounded-md px-3 py-2 text-base font-medium bg-gray-700 animate-pulse"
									>
										<div className="h-4 w-24 bg-gray-600 animate-pulse"></div>
									</DisclosureButton>
								))}
							</div>
						</div>
					</DisclosurePanel>
				</>
			)}
		</Disclosure>
	);
};

export default NavbarSkeleton;
