// src/pages/auth/ResetPassword.tsx
import React, { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../services/firebase";
import Logo from "../../components/Logo";

const ResetPassword: React.FC = () => {
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState<string | null>(null);
	const [error, setError] = useState<string | null>(null);

	const handleResetPassword = async (e: React.FormEvent) => {
		e.preventDefault();
		try {
			await sendPasswordResetEmail(auth, email);
			setMessage("Password reset email sent. Please check your inbox.");
			setError(null);
		} catch (error) {
			setError(
				"Failed to send password reset email. Please check the email address."
			);
			setMessage(null);
		}
	};

	return (
		<div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
			<div className="sm:mx-auto sm:w-full sm:max-w-sm">
				<Logo />
				<h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
					Reset your password
				</h2>
			</div>

			<div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
				<form className="space-y-6" onSubmit={handleResetPassword}>
					<div>
						<label
							htmlFor="email"
							className="block text-sm font-medium leading-6 text-gray-900"
						>
							Email address
						</label>
						<div className="mt-2">
							<input
								id="email"
								name="email"
								type="email"
								autoComplete="email"
								required
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
							/>
						</div>
					</div>

					{message && <p className="mt-2 text-sm text-green-600">{message}</p>}
					{error && <p className="mt-2 text-sm text-red-600">{error}</p>}

					<div>
						<button
							type="submit"
							className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
						>
							Reset Password
						</button>
					</div>
				</form>

				<p className="mt-10 text-center text-sm text-gray-500">
					Remember your password?{" "}
					<a
						href="/auth/login"
						className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
					>
						Sign in
					</a>
				</p>
			</div>
		</div>
	);
};

export default ResetPassword;
