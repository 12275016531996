// src/pages/auth/SignUp.tsx
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { auth, db } from "../../services/firebase";
import Logo from "../../components/Logo";

const SignUp: React.FC = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [displayName, setDisplayName] = useState("");
	const [organization, setOrganization] = useState("DIS");
	const [role, setRole] = useState("Student");
	const [error, setError] = useState<string | null>(null);
	const navigate = useNavigate();

	const handleSignUp = async (e: React.FormEvent) => {
		e.preventDefault();
		try {
			const userCredential = await createUserWithEmailAndPassword(
				auth,
				email,
				password
			);
			const user = userCredential.user;
			await updateProfile(user, { displayName });

			// Create user document in db
			await setDoc(doc(db, "users", user.uid), {
				uid: user.uid,
				displayName,
				email,
				photoURL: null,
				organization,
				role,
			});

			navigate("/dashboard");
		} catch (error) {
			setError("Failed to sign up. Please try again.");
		}
	};

	return (
		<div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
			<div className="sm:mx-auto sm:w-full sm:max-w-sm">
				<Logo />
				<h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
					Sign up for an account
				</h2>
			</div>
			<div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
				<form className="space-y-6" onSubmit={handleSignUp}>
					<div>
						<label
							htmlFor="displayName"
							className="block text-sm font-medium leading-6 text-gray-900"
						>
							Display Name
						</label>
						<div className="mt-2">
							<input
								id="displayName"
								name="displayName"
								type="text"
								autoComplete="name"
								required
								value={displayName}
								onChange={(e) => setDisplayName(e.target.value)}
								className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
							/>
						</div>
					</div>
					<div>
						<label
							htmlFor="email"
							className="block text-sm font-medium leading-6 text-gray-900"
						>
							Email address
						</label>
						<div className="mt-2">
							<input
								id="email"
								name="email"
								type="email"
								autoComplete="email"
								required
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
							/>
						</div>
					</div>
					<div>
						<label
							htmlFor="password"
							className="block text-sm font-medium leading-6 text-gray-900"
						>
							Password
						</label>
						<div className="mt-2">
							<input
								id="password"
								name="password"
								type="password"
								autoComplete="new-password"
								required
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
							/>
						</div>
					</div>
					<div>
						<label
							htmlFor="organization"
							className="block text-sm font-medium leading-6 text-gray-900"
						>
							Organization
						</label>
						<div className="mt-2">
							<select
								id="organization"
								name="organization"
								required
								value={organization}
								onChange={(e) => setOrganization(e.target.value)}
								className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
							>
								<option value="streamlive" disabled>
									streamlive
								</option>
								<option value="DIS">DIS</option>
								<option value="SJA">SJA</option>
							</select>
						</div>
					</div>
					<div>
						<label
							htmlFor="role"
							className="block text-sm font-medium leading-6 text-gray-900"
						>
							Role
						</label>
						<div className="mt-2">
							<select
								id="role"
								name="role"
								required
								value={role}
								onChange={(e) => setRole(e.target.value)}
								className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
							>
								<option value="Student">Student</option>
								<option value="Faculty">Faculty</option>
								<option value="Guest">Guest</option>
								<option value="Athletic Council" disabled>
									Athletic Council
								</option>
								<option value="Athletic Director" disabled>
									Athletic Director
								</option>
							</select>
						</div>

						<p className="mt-6 text-xs leading-5 text-gray-600">
							If you idefity as Athletic Council or Director, please contact
							streamlive customer support after creating an account with any
							role. These roles need manual editing for permission. And or, if
							you have purchased a license and don't see your organization on
							the dropdown menu, please contact streamlive customer support.
						</p>
						<p className="mt-6 text-xs leading-5 text-gray-600">
							<b>
								By signing in, you acknowledge that you understand the following
								disclaimer and agree to the terms and services.{" "}
							</b>
						</p>
					</div>
					{error && <p className="mt-2 text-sm text-red-600">{error}</p>}
					<div>
						<button
							type="submit"
							className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
						>
							Sign up
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default SignUp;
