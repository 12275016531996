// src/pages/company/CompanyHomePage.tsx
import { useState } from "react";
import Text_Logo from "../../assets/text_logo.png";
import { Dialog, DialogPanel } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import CompanyFeature from "../../components/company/Features";
import Team from "../../components/company/Team";
import Usedby from "../../components/company/Usedby";
import Pricing from "../../components/company/Pricing";
import Footer from "../../components/Footer";

const navigation = [
	// { name: "Product", href: "/product" },
	// { name: "Features", href: "/features" },
	{ name: "Advertisement", href: "/advertisement" },
];

const translations = {
	en: {
		title: "Redefining the Future of Sports Streaming",
		description:
			'Experience the next evolution in sports entertainment with our platform, "Redefining the Future of Sports Streaming." Enjoy high-definition live sports, instant replays, and interactive stats, all in one place. Access your favorite events anytime, anywhere, and never miss a moment of the action.',
		getStarted: "Get started",
		learnMore: "Learn more",
	},
	ko: {
		title: "스포츠 스트리밍의 미래 재정의",
		description:
			'"스포츠 스트리밍의 미래 재정의" 플랫폼을 통해 스포츠 엔터테인먼트의 다음 진화를 경험하세요. 고화질 라이브 스포츠, 즉각 재생, 상호작용 통계를 한 곳에서 모두 즐기세요. 언제 어디서나 좋아하는 이벤트에 접속하고 액션의 순간을 놓치지 마세요.',
		getStarted: "시작하기",
		learnMore: "자세히 알아보기",
	},
} as const;

type Language = keyof typeof translations;

const CompanyHomePage: React.FC = () => {
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
	const [language, setLanguage] = useState<Language>("en");

	const handleLanguageToggle = () => {
		setLanguage((prevLanguage) => (prevLanguage === "en" ? "ko" : "en"));
	};

	const { title, description, getStarted, learnMore } = translations[language];

	return (
		<div className="bg-white">
			<header className="absolute inset-x-0 top-0 z-50">
				<nav
					className="flex items-center justify-between p-6 lg:px-8"
					aria-label="Global"
				>
					<div className="flex lg:flex-1">
						<a href="/" className="-m-1.5 p-1.5">
							<img className="h-12 w-auto" src={Text_Logo} alt="streamlive" />
						</a>
					</div>
					<div className="flex lg:hidden">
						<button
							type="button"
							className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
							onClick={() => setMobileMenuOpen(true)}
						>
							<span className="sr-only">Open main menu</span>
							<Bars3Icon className="h-6 w-6" aria-hidden="true" />
						</button>
					</div>
					<div className="hidden lg:flex lg:gap-x-12">
						{navigation.map((item) => (
							<a
								key={item.name}
								href={item.href}
								className="text-sm font-semibold leading-6 text-gray-900"
							>
								{item.name}
							</a>
						))}
					</div>
					<div className="hidden lg:flex lg:flex-1 lg:justify-end">
						<a
							href="/auth/login"
							className="text-sm font-semibold leading-6 text-gray-900"
						>
							Log in <span aria-hidden="true">&rarr;</span>
						</a>
					</div>
				</nav>
				<Dialog
					className="lg:hidden"
					open={mobileMenuOpen}
					onClose={() => setMobileMenuOpen(false)}
				>
					<div className="fixed inset-0 z-50" />
					<DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
						<div className="flex items-center justify-between">
							<a href="/" className="-m-1.5 p-1.5">
								<img className="h-8 w-auto" src={Text_Logo} alt="streamlive" />
							</a>
							<button
								type="button"
								className="-m-2.5 rounded-md p-2.5 text-gray-700"
								onClick={() => setMobileMenuOpen(false)}
							>
								<span className="sr-only">Close menu</span>
								<XMarkIcon className="h-6 w-6" aria-hidden="true" />
							</button>
						</div>
						<div className="mt-6 flow-root">
							<div className="-my-6 divide-y divide-gray-500/10">
								<div className="space-y-2 py-6">
									{navigation.map((item) => (
										<a
											key={item.name}
											href={item.href}
											className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
										>
											{item.name}
										</a>
									))}
								</div>
								<div className="py-6">
									<a
										href="/auth/login"
										className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
									>
										Log in
									</a>
								</div>
							</div>
						</div>
					</DialogPanel>
				</Dialog>
			</header>

			<div className="relative isolate px-6 pt-14 lg:px-8">
				<div
					className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
					aria-hidden="true"
				>
					<div
						className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
						style={{
							clipPath:
								"polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
						}}
					/>
				</div>
				<div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
					<div className="hidden sm:mb-8 sm:flex sm:justify-center">
						<div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
							Announcing streamlive's Acquisition of KISAC.live.{" "}
							<a href="/blog" className="font-semibold text-indigo-600">
								<span className="absolute inset-0" aria-hidden="true" />
								Read more <span aria-hidden="true">&rarr;</span>
							</a>
						</div>
					</div>
					<div className="text-center">
						<h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
							{title}
						</h1>
						<p className="mt-6 text-lg leading-8 text-gray-600">
							{description}
						</p>
						<div className="mt-10 flex items-center justify-center gap-x-6">
							<a
								href="/get-started"
								className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
							>
								{getStarted}
							</a>
							<a
								href="/learn-more"
								className="text-sm font-semibold leading-6 text-gray-900"
							>
								{learnMore} <span aria-hidden="true">→</span>
							</a>
						</div>
						<div className="mt-6">
							<button
								onClick={handleLanguageToggle}
								className="text-sm font-semibold leading-6 text-gray-900"
							>
								{language === "en" ? "한국어로 보기" : "View in English"}
							</button>
						</div>
					</div>
				</div>
				<div
					className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
					aria-hidden="true"
				>
					<div
						className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
						style={{
							clipPath:
								"polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
						}}
					/>
				</div>
			</div>
			<Usedby language={language} />
			<CompanyFeature language={language} />
			<Team language={language} />
			<Pricing language={language} />
			<Footer />
		</div>
	);
};

export default CompanyHomePage;
