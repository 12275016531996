// src/components/CompanyStats.tsx
import React from "react";

const stats = [
	{ id: 1, name: "Total Views", value: "138k+" },
	{ id: 2, name: "Active Users", value: "750+" },
	{ id: 3, name: "Avg Engagement Time", value: "~3 mins" },
];

const CompanyStats: React.FC = () => {
	return (
		<div className="mx-auto max-w-7xl px-1 lg:px-1  py-10 sm:py-12">
			<div className="bg-white rounded-lg shadow-md p-8">
				<dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
					{stats.map((stat) => (
						<div
							key={stat.id}
							className="mx-auto flex max-w-xs flex-col gap-y-4"
						>
							<dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
								{stat.value}
							</dd>
							<dt className="text-base leading-7 text-gray-600">{stat.name}</dt>
						</div>
					))}
				</dl>
			</div>
		</div>
	);
};

export default CompanyStats;
