// src/components/AdminRoute.tsx
import React, { ReactNode, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "../services/firebase";

interface AdminRouteProps {
	children: ReactNode;
}

const AdminRoute: React.FC<AdminRouteProps> = ({ children }) => {
	const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
	const [isAdmin, setIsAdmin] = useState<boolean | null>(null);

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, async (user) => {
			if (user) {
				setIsAuthenticated(true);
				const userDoc = await getDoc(doc(db, "users", user.uid));
				if (userDoc.exists()) {
					const userData = userDoc.data();
					setIsAdmin(userData.role === "Admin");
				} else {
					setIsAdmin(false);
				}
			} else {
				setIsAuthenticated(false);
			}
		});

		return () => unsubscribe();
	}, []);

	if (isAuthenticated === null || isAdmin === null) {
		return <div>Loading...</div>; // Or a loading spinner
	}

	if (!isAuthenticated) {
		return <Navigate to="/auth/login" />;
	}

	if (!isAdmin) {
		return <Navigate to="/not-authorized" />;
	}

	return <>{children}</>;
};

export default AdminRoute;
