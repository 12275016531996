// src/pages/organization/AdminDashboardPage.tsx
import React, { useState, useEffect } from "react";
import { collection, addDoc, getDocs, DocumentData } from "firebase/firestore";
import { db } from "../../services/firebase";

const AdminDashboardPage: React.FC = () => {
	const [newTeam, setNewTeam] = useState({
		schoolName: "",
		location: "",
		conference: "",
		numTeams: 0,
		sports: "",
		logoUrl: "",
		organizationRef: "",
	});
	const [organizations, setOrganizations] = useState<DocumentData[]>([]);

	useEffect(() => {
		const fetchOrganizations = async () => {
			const querySnapshot = await getDocs(collection(db, "organizations"));
			const orgs = querySnapshot.docs.map((doc) => ({
				id: doc.id,
				...doc.data(),
			}));
			setOrganizations(orgs);
		};

		fetchOrganizations();
	}, []);

	const handleInputChange = (
		e:
			| React.ChangeEvent<HTMLInputElement>
			| React.ChangeEvent<HTMLSelectElement>
	) => {
		const { name, value } = e.target;
		setNewTeam({ ...newTeam, [name]: value });
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		try {
			await addDoc(collection(db, "teams"), newTeam);
			setNewTeam({
				schoolName: "",
				location: "",
				conference: "",
				numTeams: 0,
				sports: "",
				logoUrl: "",
				organizationRef: "",
			});
		} catch (error) {
			console.error("Error adding team: ", error);
		}
	};

	return (
		<div className="min-h-full">
			<header className="bg-white shadow">
				<div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
					<h1 className="text-3xl font-bold tracking-tight text-gray-900">
						Admin Dashboard
					</h1>
				</div>
			</header>
			<main>
				<div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
					<div className="bg-white rounded-lg p-6">
						<h2 className="text-2xl font-bold mb-4">Add New Team</h2>
						<form onSubmit={handleSubmit}>
							<div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
								<div className="sm:col-span-3">
									<label
										htmlFor="schoolName"
										className="block text-sm font-medium text-gray-700"
									>
										School Name
									</label>
									<div className="mt-1">
										<input
											type="text"
											name="schoolName"
											id="schoolName"
											value={newTeam.schoolName}
											onChange={handleInputChange}
											className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
											required
										/>
									</div>
								</div>

								<div className="sm:col-span-3">
									<label
										htmlFor="location"
										className="block text-sm font-medium text-gray-700"
									>
										Location
									</label>
									<div className="mt-1">
										<input
											type="text"
											name="location"
											id="location"
											value={newTeam.location}
											onChange={handleInputChange}
											className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
											required
										/>
									</div>
								</div>

								<div className="sm:col-span-3">
									<label
										htmlFor="conference"
										className="block text-sm font-medium text-gray-700"
									>
										Conference
									</label>
									<div className="mt-1">
										<input
											type="text"
											name="conference"
											id="conference"
											value={newTeam.conference}
											onChange={handleInputChange}
											className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
											required
										/>
									</div>
								</div>

								<div className="sm:col-span-3">
									<label
										htmlFor="numTeams"
										className="block text-sm font-medium text-gray-700"
									>
										Number of Teams
									</label>
									<div className="mt-1">
										<input
											type="number"
											name="numTeams"
											id="numTeams"
											value={newTeam.numTeams}
											onChange={handleInputChange}
											className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
											required
										/>
									</div>
								</div>

								<div className="sm:col-span-3">
									<label
										htmlFor="sports"
										className="block text-sm font-medium text-gray-700"
									>
										Sports
									</label>
									<div className="mt-1">
										<input
											type="text"
											name="sports"
											id="sports"
											value={newTeam.sports}
											onChange={handleInputChange}
											className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
											required
										/>
									</div>
								</div>

								<div className="sm:col-span-3">
									<label
										htmlFor="logoUrl"
										className="block text-sm font-medium text-gray-700"
									>
										Logo URL
									</label>
									<div className="mt-1">
										<input
											type="text"
											name="logoUrl"
											id="logoUrl"
											value={newTeam.logoUrl}
											onChange={handleInputChange}
											className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
											required
										/>
									</div>
								</div>

								<div className="sm:col-span-3">
									<label
										htmlFor="organizationRef"
										className="block text-sm font-medium text-gray-700"
									>
										Organization
									</label>
									<div className="mt-1">
										<select
											name="organizationRef"
											id="organizationRef"
											value={newTeam.organizationRef}
											onChange={handleInputChange}
											className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
											required
										>
											<option value="">Select an organization</option>
											{organizations.map((org) => (
												<option key={org.id} value={org.id}>
													{org.name}
												</option>
											))}
										</select>
									</div>
								</div>
							</div>
							<div className="mt-6">
								<button
									type="submit"
									className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
								>
									Add Team
								</button>
							</div>
						</form>
					</div>
				</div>
			</main>
		</div>
	);
};

export default AdminDashboardPage;
