// src/components/Logo.tsx
import React from "react";
import Text_Logo from "../assets/text_logo.png";

const Logo: React.FC = () => {
	return (
		<div className="flex lg:flex-1">
			<a href="/" className="-m-1.5 p-1.5">
				<img className="h-12 w-auto" src={Text_Logo} alt="streamlive" />
			</a>
		</div>
	);
};

export default Logo;
