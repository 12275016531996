// src/components/dashboard/ProfileImageModal.tsx
import { useState } from "react";
import {
	Dialog,
	DialogBackdrop,
	DialogPanel,
	DialogTitle,
} from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { auth, storage, db } from "../../services/firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore";
import { User, updateProfile } from "firebase/auth";

interface ProfileImageModalProps {
	open: boolean;
	setOpen: (open: boolean) => void;
}

const ProfileImageModal: React.FC<ProfileImageModalProps> = ({
	open,
	setOpen,
}) => {
	const [file, setFile] = useState<File | null>(null);
	const [uploading, setUploading] = useState(false);
	const [error, setError] = useState<string | null>(null);

	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files && e.target.files[0]) {
			setFile(e.target.files[0]);
		}
	};

	const handleUpload = async () => {
		if (!file) {
			setError("No file selected.");
			return;
		}

		const user = auth.currentUser;
		if (!user) {
			setError("User not authenticated.");
			return;
		}

		setUploading(true);
		setError(null);

		const storageRef = ref(storage, `profileImages/${user.uid}`);
		try {
			await uploadBytes(storageRef, file);
			const downloadURL = await getDownloadURL(storageRef);

			await updateDoc(doc(db, "users", user.uid), {
				photoURL: downloadURL,
			});

			await updateProfile(user, { photoURL: downloadURL });

			setOpen(false);
		} catch (err) {
			setError("Failed to upload image. Please try again.");
		} finally {
			setUploading(false);
		}
	};

	return (
		<Dialog
			className="relative z-10"
			open={open}
			onClose={() => setOpen(false)}
		>
			<DialogBackdrop
				transition
				className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
			/>

			<div className="fixed inset-0 z-10 overflow-y-auto">
				<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
					<DialogPanel
						transition
						className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
					>
						<div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
							<div className="sm:flex sm:items-start">
								<div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
									<ExclamationTriangleIcon
										className="h-6 w-6 text-red-600"
										aria-hidden="true"
									/>
								</div>
								<div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
									<DialogTitle
										as="h3"
										className="text-base font-semibold leading-6 text-gray-900"
									>
										Upload Profile Image
									</DialogTitle>
									<div className="mt-2">
										<input type="file" onChange={handleFileChange} />
										{error && <p className="text-sm text-red-600">{error}</p>}
									</div>
								</div>
							</div>
						</div>
						<div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
							<button
								type="button"
								className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
								onClick={handleUpload}
								disabled={uploading}
							>
								{uploading ? "Uploading..." : "Upload"}
							</button>
							<button
								type="button"
								className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
								onClick={() => setOpen(false)}
								disabled={uploading}
							>
								Cancel
							</button>
						</div>
					</DialogPanel>
				</div>
			</div>
		</Dialog>
	);
};

export default ProfileImageModal;
