// src/pages/dashboard/TeamDetailPage.tsx
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
	doc,
	getDoc,
	collection,
	query,
	where,
	getDocs,
} from "firebase/firestore";
import { db } from "../../services/firebase";

interface Team {
	schoolName: string;
	location: string;
	conference: string;
	numTeams: number;
	sports: string[];
	logoUrl: string;
}

const TeamDetailPage: React.FC = () => {
	const { schoolName } = useParams<{ schoolName: string }>();
	const [team, setTeam] = useState<Team | null>(null);

	useEffect(() => {
		const fetchTeamDetail = async () => {
			if (!schoolName) return;

			// Query Firestore for a team with the given schoolName
			const teamQuery = query(
				collection(db, "teams"),
				where("schoolName", "==", schoolName)
			);
			const querySnapshot = await getDocs(teamQuery);

			if (!querySnapshot.empty) {
				const doc = querySnapshot.docs[0];
				const data = doc.data();
				setTeam({
					schoolName: data.schoolName || "",
					location: data.location || "",
					conference: data.conference || "",
					numTeams: data.numTeams || 0,
					sports: Array.isArray(data.sports) ? data.sports : [],
					logoUrl: data.logoUrl || "",
				});
			}
		};

		fetchTeamDetail();
	}, [schoolName]);

	if (!team) {
		return (
			<div className="min-h-full">
				<header className="bg-white shadow">
					<div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
						<h1 className="text-3xl font-bold tracking-tight text-gray-900">
							Team Not Found
						</h1>
					</div>
				</header>
				<main>
					<div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
						<p>No team found with the name {schoolName}</p>
					</div>
				</main>
			</div>
		);
	}

	return (
		<div className="min-h-full">
			<header className="bg-white shadow">
				<div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
					<h1 className="text-3xl font-bold tracking-tight text-gray-900">
						{team.schoolName}
					</h1>
				</div>
			</header>
			<main>
				<div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
					<div className="bg-white rounded-lg p-6">
						<div className="flex items-center">
							<img
								className="h-20 w-auto"
								src={team.logoUrl}
								alt={`${team.schoolName} logo`}
							/>
							<div className="ml-4">
								<p className="text-xl font-semibold text-gray-900">
									{team.schoolName}
								</p>
								<p className="mt-2 text-sm text-gray-500">{team.location}</p>
								<p className="mt-2 text-sm text-gray-500">{team.conference}</p>
								<p className="mt-2 text-sm text-gray-500">
									{team.numTeams} teams
								</p>
								<div className="mt-2 flex space-x-2">
									{team.sports.map((sport, sportIndex) => (
										<span key={sportIndex} className="text-lg">
											{sport}
										</span>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		</div>
	);
};

export default TeamDetailPage;
