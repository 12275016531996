// src/routes/DashboardRoutes.tsx
import React from "react";
import { Route, Routes } from "react-router-dom";
import DashboardHomePage from "../pages/dashboard/DashboardHomePage";
import TeamsPage from "../pages/dashboard/TeamsPage";
import SchedulesPage from "../pages/dashboard/SchedulesPage";
import RankingsPage from "../pages/dashboard/RankingsPage";
import AdminDashboardPage from "../pages/organization/AdminDashboardPage";
import ProfilePage from "../pages/dashboard/ProfilePage";
import PrivateRoute from "../components/PrivateRoute";
import AdminRoute from "../components/AdminRoute"; // Import the new AdminRoute component
import NotFoundPage from "../pages/NotFoundPage";
import Navbar from "../components/dashboard/Navbar";
import TeamDetailPage from "../pages/dashboard/TeamsDetailedPage";

const DashboardRoutes: React.FC = () => {
	return (
		<div>
			<Navbar />
			<Routes>
				<Route path="*" element={<NotFoundPage />} />
				<Route
					path="/"
					element={
						<PrivateRoute>
							<DashboardHomePage />
						</PrivateRoute>
					}
				/>
				<Route
					path="/teams/:schoolName"
					element={
						<PrivateRoute>
							<TeamDetailPage />
						</PrivateRoute>
					}
				/>
				<Route
					path="/teams"
					element={
						<PrivateRoute>
							<TeamsPage />
						</PrivateRoute>
					}
				/>
				<Route
					path="/schedules"
					element={
						<PrivateRoute>
							<SchedulesPage />
						</PrivateRoute>
					}
				/>
				<Route
					path="/rankings"
					element={
						<PrivateRoute>
							<RankingsPage />
						</PrivateRoute>
					}
				/>
				<Route
					path="/profile"
					element={
						<PrivateRoute>
							<ProfilePage />
						</PrivateRoute>
					}
				/>
				<Route
					path="/admin"
					element={
						<AdminRoute>
							<AdminDashboardPage />
						</AdminRoute>
					}
				/>
			</Routes>
		</div>
	);
};

export default DashboardRoutes;
