// src/contexts/UserContext.tsx
import React, { createContext, useContext, useState, ReactNode } from "react";

interface UserContextType {
	user: { name: string; email: string } | null;
	setUser: (user: { name: string; email: string } | null) => void;
}

interface UserProviderProps {
	children: ReactNode;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
	const [user, setUser] = useState<{ name: string; email: string } | null>(
		null
	);

	return (
		<UserContext.Provider value={{ user, setUser }}>
			{children}
		</UserContext.Provider>
	);
};

export const useUser = () => {
	const context = useContext(UserContext);
	if (!context) {
		throw new Error("useUser must be used within a UserProvider");
	}
	return context;
};
