// src/App.tsx
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CompanyRoutes from "./routes/CompanyRoutes";
import DashboardRoutes from "./routes/DashboardRoutes";
import AuthRoutes from "./routes/AuthRoutes";
import { AuthProvider } from "./contexts/AuthContext";
import { UserProvider } from "./contexts/UserContext";
import NotFoundPage from "./pages/NotFoundPage";

const App: React.FC = () => {
	return (
		<AuthProvider>
			<UserProvider>
				<Router>
					<Routes>
						{/* Public Routes */}
						<Route path="/*" element={<CompanyRoutes />} />
						{/* Authentication Routes */}
						<Route path="/auth/*" element={<AuthRoutes />} />
						{/* Dashboard Routes (Protected) */}
						<Route path="/dashboard/*" element={<DashboardRoutes />} />
						{/* Catch-all Route for undefined paths */}
						<Route path="*" element={<NotFoundPage />} />
					</Routes>
				</Router>
			</UserProvider>
		</AuthProvider>
	);
};

export default App;
