// src/components/TrustedBySection.tsx
import React from "react";

const translations = {
	en: {
		sectionTitle: "Used by renowned organizations around the world",
	},
	ko: {
		sectionTitle: "전 세계의 저명한 조직이 사용",
	},
} as const;

type Language = keyof typeof translations;

const TrustedBySection: React.FC<{ language: Language }> = ({ language }) => {
	const { sectionTitle } = translations[language];

	return (
		<div className="bg-white py-24 sm:py-32">
			<div className="mx-auto max-w-7xl px-6 lg:px-8">
				<h2 className="text-center text-lg font-semibold leading-8 text-gray-900">
					{sectionTitle}
				</h2>
				<div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-3">
					<img
						className="col-span-2 max-h-20 w-full object-contain lg:col-span-1"
						src="https://firebasestorage.googleapis.com/v0/b/streamlive-b6f69.appspot.com/o/Usedby%2Fdis.png?alt=media&token=10a660e5-4834-495d-a844-3c999c2a65c1"
						alt="DIS"
						width={158}
						height={48}
					/>
					<img
						className="col-span-2 max-h-20 w-full object-contain lg:col-span-1"
						src="https://firebasestorage.googleapis.com/v0/b/streamlive-b6f69.appspot.com/o/Usedby%2Fkisac.png?alt=media&token=8f4dc722-fc15-49a1-98e2-2e2ea1de8fbf"
						alt="KISAC"
						width={158}
						height={15}
					/>
					<img
						className="col-span-2 max-h-20 w-full object-contain lg:col-span-1"
						src="https://firebasestorage.googleapis.com/v0/b/streamlive-b6f69.appspot.com/o/Usedby%2Fsjajeju.png?alt=media&token=653aa831-17da-4132-a3f2-4eb2a1795dfb"
						alt="SJA"
						width={158}
						height={48}
					/>
				</div>
			</div>
		</div>
	);
};

export default TrustedBySection;
