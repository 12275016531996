import React from "react";

const HomePage: React.FC = () => {
	return (
		<div className="container mx-auto p-4">
			<h1 className="text-4xl font-bold text-center">Pricing</h1>
			<p className="mt-4 text-center">Pricing</p>
		</div>
	);
};

export default HomePage;
