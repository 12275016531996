// src/routes/AuthRoutes.tsx
import React from "react";
import { Route, Routes } from "react-router-dom";
import SignIn from "../pages/auth/LoginPage";
import SignUp from "../pages/auth/SignupPage";
import ResetPassword from "../pages/auth/ResetPasswordPage";
import NotFoundPage from "../pages/NotFoundPage";

const AuthRoutes: React.FC = () => {
	return (
		<Routes>
			<Route path="/login" element={<SignIn />} />
			<Route path="/signup" element={<SignUp />} />
			<Route path="/reset-password" element={<ResetPassword />} />
			<Route path="*" element={<NotFoundPage />} />
		</Routes>
	);
};

export default AuthRoutes;
