// src/components/PricingSection.tsx
import React from "react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
	"pk_live_51PUkCt2Lc3h56cenJrYUtfRRKEPEF0IYCC03DdVprzeTkjGT98Xe5rD9AOmebES2kOVWdPwNtHCgiBobPDRwoCmN00h4lVgWQZ"
);

const translations = {
	en: {
		sectionTitle: "Simple no-tricks pricing",
		description:
			"Enjoy transparent and straightforward pricing with no hidden fees. Our goal is to provide excellent value and a seamless experience.",
		yearlyTitle: "Yearly License",
		yearlyDescription:
			"Get unlimited access to all our features with a yearly license. Benefit from continuous updates and dedicated support.",
		includedTitle: "What’s included",
		paymentTerms: "Pay yearly, enjoy uninterrupted access",
		price: "$649",
		educationalTitle: "Educational Yearly License",
		educationalDescription:
			"Designed specifically for educational institutions, this license offers unlimited access with tailored support and resources.",
		includedFeatures: [
			"Unlimited Streaming & Games",
			"Access for entire organization & their guests",
			"24/7 Customer Support",
			"Request for custom features",
		],
	},
	ko: {
		sectionTitle: "간단한 가격 정책",
		description:
			"투명하고 직관적인 가격 정책을 제공합니다. 숨겨진 비용 없이 최고의 가치를 제공합니다.",
		yearlyTitle: "연간 라이센스",
		yearlyDescription:
			"연간 라이센스로 모든 기능에 무제한으로 액세스하십시오. 지속적인 업데이트와 전담 지원을 받으십시오.",
		includedTitle: "포함된 내용",
		paymentTerms: "연간 결제, 끊김 없는 액세스",
		price: "$649",
		educationalTitle: "교육 연간 라이센스",
		educationalDescription:
			"교육 기관을 위해 특별히 설계된 이 라이센스는 무제한 액세스와 맞춤 지원 및 리소스를 제공합니다.",
		includedFeatures: [
			"무제한 스트리밍 및 게임",
			"전체 조직 및 게스트에 대한 액세스",
			"24/7 고객 지원",
			"맞춤 기능 요청",
		],
	},
} as const;

type Language = keyof typeof translations;

const PricingSection: React.FC<{ language: Language }> = ({ language }) => {
	const {
		sectionTitle,
		description,
		yearlyTitle,
		yearlyDescription,
		includedTitle,
		paymentTerms,
		price,
		educationalTitle,
		educationalDescription,
		includedFeatures,
	} = translations[language];

	const handleCheckout = async (priceId: string) => {
		const stripe = await stripePromise;
		const response = await fetch(
			"https://us-central1-streamlive-b6f69.cloudfunctions.net/createCheckoutSession",
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ priceId }),
			}
		);
		const session = await response.json();
		const result = await stripe!.redirectToCheckout({
			sessionId: session.id,
		});
		if (result.error) {
			console.error("Stripe checkout error:", result.error);
		}
	};

	return (
		<div className="bg-white py-24 sm:py-32">
			<div className="mx-auto max-w-7xl px-6 lg:px-8">
				<div className="mx-auto max-w-2xl sm:text-center">
					<h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
						{sectionTitle}
					</h2>
					<p className="mt-6 text-lg leading-8 text-gray-600">{description}</p>
				</div>
				<div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
					<div className="p-8 sm:p-10 lg:flex-auto">
						<h3 className="text-2xl font-bold tracking-tight text-gray-900">
							{yearlyTitle}
						</h3>
						<p className="mt-6 text-base leading-7 text-gray-600">
							{yearlyDescription}
						</p>
						<div className="mt-10 flex items-center gap-x-4">
							<h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">
								{includedTitle}
							</h4>
							<div className="h-px flex-auto bg-gray-100" />
						</div>
						<ul
							role="list"
							className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
						>
							{includedFeatures.map((feature) => (
								<li key={feature} className="flex gap-x-3">
									<CheckIcon
										className="h-6 w-5 flex-none text-indigo-600"
										aria-hidden="true"
									/>
									{feature}
								</li>
							))}
						</ul>
					</div>
					<div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
						<div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
							<div className="mx-auto max-w-xs px-8">
								<p className="text-base font-semibold text-gray-600">
									{paymentTerms}
								</p>
								<p className="mt-6 flex items-baseline justify-center gap-x-2">
									<span className="text-5xl font-bold tracking-tight text-gray-900">
										{price}
									</span>
									<span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">
										USD
									</span>
								</p>
								<button
									onClick={() =>
										handleCheckout("price_1PUkQ42Lc3h56cenm8L5w5uv")
									}
									className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
								>
									Get access
								</button>
								<p className="mt-6 text-xs leading-5 text-gray-600">
									Invoices and receipts available for easy company reimbursement
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
					<div className="p-8 sm:p-10 lg:flex-auto">
						<h3 className="text-2xl font-bold tracking-tight text-gray-900">
							{educationalTitle}
						</h3>
						<p className="mt-6 text-base leading-7 text-gray-600">
							{educationalDescription}
						</p>
						<div className="mt-10 flex items-center gap-x-4">
							<h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">
								{includedTitle}
							</h4>
							<div className="h-px flex-auto bg-gray-100" />
						</div>
						<ul
							role="list"
							className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
						>
							{includedFeatures.map((feature) => (
								<li key={feature} className="flex gap-x-3">
									<CheckIcon
										className="h-6 w-5 flex-none text-indigo-600"
										aria-hidden="true"
									/>
									{feature}
								</li>
							))}
						</ul>
					</div>
					<div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
						<div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
							<div className="mx-auto max-w-xs px-8">
								<p className="text-base font-semibold text-gray-600">
									{paymentTerms}
								</p>
								<p className="mt-6 flex items-baseline justify-center gap-x-2">
									<span className="text-5xl font-bold tracking-tight text-gray-900">
										$499
									</span>
									<span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">
										USD
									</span>
								</p>
								<button
									onClick={() =>
										handleCheckout("price_1PUkP82Lc3h56cenK9AV57gE")
									}
									className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
								>
									Get access
								</button>
								<p className="mt-6 text-xs leading-5 text-gray-600">
									Invoices and receipts available for easy company reimbursement
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PricingSection;
