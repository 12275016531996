import React, { useState } from "react";
import {
	format,
	startOfMonth,
	endOfMonth,
	startOfWeek,
	endOfWeek,
	addDays,
	addMonths,
	subMonths,
	isSameMonth,
	isSameDay,
	parse,
} from "date-fns";

const SchedulePage: React.FC = () => {
	const [view, setView] = useState<"day" | "week" | "month">("month");
	const [currentMonth, setCurrentMonth] = useState(new Date());
	const [selectedDate, setSelectedDate] = useState<Date>(new Date());

	const events = [
		{
			eventType: "Match",
			sport: "Soccer",
			teams: ["Team A", "Team B"],
			sex: "Male",
			name: "Soccer Championship",
			date: "2024-06-10T17:00:00",
			location: "Starbucks",
			imageUrl:
				"https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=256&q=80",
		},
		{
			eventType: "Training",
			sport: "Basketball",
			teams: ["Team C"],
			sex: "Female",
			name: "Basketball Training",
			date: "2022-01-12T15:00:00",
			location: "Tim Hortons",
			imageUrl:
				"https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&auto=format&fit=crop&w=256&q=80",
		},
		// Add more events as needed
	];

	const renderHeader = () => {
		return (
			<div className="flex justify-between items-center mb-4">
				<h2 className="text-lg font-semibold text-gray-900">
					{format(currentMonth, "MMMM yyyy")}
				</h2>
				<div className="flex space-x-2">
					<button
						onClick={prevMonth}
						className="text-gray-400 hover:text-gray-600"
					>
						<svg
							className="h-5 w-5"
							fill="none"
							stroke="currentColor"
							viewBox="0 0 24 24"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M15 19l-7-7 7-7"
							/>
						</svg>
					</button>
					<button
						onClick={nextMonth}
						className="text-gray-400 hover:text-gray-600"
					>
						<svg
							className="h-5 w-5"
							fill="none"
							stroke="currentColor"
							viewBox="0 0 24 24"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M9 5l7 7-7 7"
							/>
						</svg>
					</button>
				</div>
			</div>
		);
	};

	const renderDays = () => {
		const days = [];
		const startDate = startOfWeek(currentMonth);
		for (let i = 0; i < 7; i++) {
			days.push(
				<div key={i} className="text-center py-2">
					{format(addDays(startDate, i), "E")}
				</div>
			);
		}
		return <div className="grid grid-cols-7">{days}</div>;
	};

	const renderCells = () => {
		const monthStart = startOfMonth(currentMonth);
		const monthEnd = endOfMonth(monthStart);
		const startDate = startOfWeek(monthStart);
		const endDate = endOfWeek(monthEnd);
		const rows = [];
		let days = [];
		let day = startDate;
		let formattedDate = "";

		while (day <= endDate) {
			for (let i = 0; i < 7; i++) {
				formattedDate = format(day, "d");
				const cloneDay = day;
				days.push(
					<div
						className={`${
							!isSameMonth(day, monthStart)
								? "text-gray-400"
								: isSameDay(day, selectedDate)
									? "bg-blue-500 text-white"
									: "text-gray-900"
						} text-center py-2 cursor-pointer`}
						key={day.toString()}
						onClick={() => onDateClick(cloneDay)}
					>
						<span>{formattedDate}</span>
					</div>
				);
				day = addDays(day, 1);
			}
			rows.push(
				<div className="grid grid-cols-7" key={day.toString()}>
					{days}
				</div>
			);
			days = [];
		}
		return <div>{rows}</div>;
	};

	const onDateClick = (day: Date) => {
		setSelectedDate(day);
	};

	const nextMonth = () => {
		setCurrentMonth(addMonths(currentMonth, 1));
	};

	const prevMonth = () => {
		setCurrentMonth(subMonths(currentMonth, 1));
	};

	const renderEvents = () => {
		const filteredEvents = events.filter((event) => {
			const eventDate = parse(event.date, "yyyy-MM-dd'T'HH:mm:ss", new Date());
			if (view === "day") {
				return isSameDay(eventDate, selectedDate);
			} else if (view === "week") {
				const start = startOfWeek(selectedDate);
				const end = endOfWeek(start);
				return eventDate >= start && eventDate <= end;
			} else {
				return isSameMonth(eventDate, currentMonth);
			}
		});

		return filteredEvents.map((event, index) => (
			<li key={index} className="py-4 flex">
				<img
					className="h-10 w-10 rounded-full"
					src={event.imageUrl}
					alt={event.name}
				/>
				<div className="ml-3">
					<p className="text-sm font-medium text-gray-900">{event.name}</p>
					<p className="text-sm text-gray-500">
						{format(
							parse(event.date, "yyyy-MM-dd'T'HH:mm:ss", new Date()),
							"PPPpp"
						)}
					</p>
					<p className="text-sm text-gray-500">{event.location}</p>
					<p className="text-sm text-gray-500">{event.sport}</p>
					<p className="text-sm text-gray-500">{event.teams.join(" vs ")}</p>
					<p className="text-sm text-gray-500">{event.sex}</p>
					<p className="text-sm text-gray-500">{event.eventType}</p>
				</div>
			</li>
		));
	};

	return (
		<div className="min-h-full">
			<header className="bg-white shadow">
				<div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
					<h1 className="text-3xl font-bold tracking-tight text-gray-900">
						Schedule
					</h1>
				</div>
			</header>
			<main>
				<div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
					<div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
						<div className="lg:flex lg:items-center lg:justify-between">
							<div className="flex-1 min-w-0">
								<h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
									Upcoming Events
								</h2>
							</div>
							<div className="mt-5 flex lg:mt-0 lg:ml-4">
								<button
									onClick={() => setView("day")}
									className={`ml-3 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
										view === "day" ? "ring-2 ring-offset-2 ring-indigo-500" : ""
									}`}
								>
									Day
								</button>
								<button
									onClick={() => setView("week")}
									className={`ml-3 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
										view === "week"
											? "ring-2 ring-offset-2 ring-indigo-500"
											: ""
									}`}
								>
									Week
								</button>
								<button
									onClick={() => setView("month")}
									className={`ml-3 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
										view === "month"
											? "ring-2 ring-offset-2 ring-indigo-500"
											: ""
									}`}
								>
									Month
								</button>
							</div>
						</div>

						<div className="mt-10 flex flex-col-reverse md:grid md:grid-cols-3 gap-8">
							{/* Events List */}
							<div className="md:col-span-2">
								<ul className="divide-y divide-gray-200">{renderEvents()}</ul>
							</div>
							{/* Calendar */}
							<div className="bg-white rounded-lg shadow-md p-6">
								{renderHeader()}
								{renderDays()}
								{renderCells()}
							</div>
						</div>
					</div>
				</div>
			</main>
		</div>
	);
};

export default SchedulePage;
