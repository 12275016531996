// src/pages/dashboard/TeamsPage.tsx
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../services/firebase";

interface Team {
	schoolName: string;
	location: string;
	conference: string;
	numTeams: number;
	sports: string[];
	logoUrl: string;
}

export default function TeamsPage() {
	const [teams, setTeams] = useState<Team[]>([]);

	useEffect(() => {
		const fetchTeams = async () => {
			const querySnapshot = await getDocs(collection(db, "teams"));
			const teamsData: Team[] = querySnapshot.docs.map((doc) => {
				const data = doc.data();
				return {
					schoolName: data.schoolName || "",
					location: data.location || "",
					conference: data.conference || "",
					numTeams: data.numTeams || 0,
					sports: Array.isArray(data.sports) ? data.sports : [],
					logoUrl: data.logoUrl || "",
				} as Team;
			});
			setTeams(teamsData);
		};

		fetchTeams();
	}, []);

	return (
		<div className="min-h-full">
			<header className="bg-white shadow">
				<div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
					<h1 className="text-3xl font-bold tracking-tight text-gray-900">
						Teams
					</h1>
				</div>
			</header>
			<main>
				<div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
					<ul
						role="list"
						className="divide-y divide-gray-100 rounded-lg bg-white"
					>
						{teams.map((team) => (
							<li key={team.schoolName} className="hover:bg-gray-50">
								<Link
									to={`/dashboard/teams/${team.schoolName}`}
									className="block"
								>
									<div className="flex justify-between gap-x-6 py-5 px-4">
										<div className="flex min-w-0 gap-x-4">
											<img
												className="h-12 w-auto bg-gray-50"
												src={team.logoUrl}
												alt={team.schoolName}
											/>
											<div className="min-w-0 flex-auto">
												<p className="text-sm font-semibold leading-6 text-gray-900">
													{team.schoolName}
												</p>
												<p className="mt-1 text-sm leading-5 text-gray-500">
													{team.location}
												</p>
												<p className="mt-1 text-sm leading-5 text-gray-500">
													{team.conference}
												</p>
												<p className="mt-1 text-sm leading-5 text-gray-500">
													{team.numTeams} teams
												</p>
											</div>
										</div>
										<div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
											<div className="mt-2 flex space-x-2">
												{team.sports.map((sport, sportIndex) => (
													<span key={sportIndex} className="text-lg">
														{sport}
													</span>
												))}
											</div>
										</div>
									</div>
								</Link>
							</li>
						))}
					</ul>
				</div>
			</main>
		</div>
	);
}
