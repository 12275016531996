import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "../pages/company/CompanyHomePage";
import PricingPage from "../pages/company/PricingPage";
import AdvertisementPage from "../pages/company/AdvertisementPage";
import FeaturesPage from "../pages/company/FeaturesPage";
import BlogPostPage from "../pages/company/BlogPage";
import NotFoundPage from "../pages/NotFoundPage";

const CompanyRoutes: React.FC = () => {
	return (
		<Routes>
			<Route path="/" element={<HomePage />} />
			<Route path="/pricing" element={<PricingPage />} />
			<Route path="/blog" element={<BlogPostPage />} />
			<Route path="/advertisement" element={<AdvertisementPage />} />
			<Route path="/features" element={<FeaturesPage />} />
			<Route path="*" element={<NotFoundPage />} />
		</Routes>
	);
};

export default CompanyRoutes;
